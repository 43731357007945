import request from '@/utils/request2'
/*
当前文件为某个客户的特殊开发，不需要这不转移到app或小程序！！！！！！！！！！！！！！
*/
// 获取皇派评价
export function gethpKpi(params) {
  return request(
    {
      url: '/hp/gethpKpi',
      method: 'get',
      params: params
    },
    'http://59.39.62.58:8016'
  )
}

// 保存皇派评价
export function savehpKpi(data) {
  return request(
    {
      url: '/hp/savehpKpi',
      method: 'post',
      data: data
    },
    'http://59.39.62.58:8016'
  )
}

// 提交审批流
export function EditWorkFlow(data) {
  return request(
    {
      url: '/hp/EditWorkFlow',
      method: 'post',
      data: data
      // headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
    },
    'http://59.39.62.58:8016'
  )
}

// 获取节点权限
export function GetNodeidPower(params) {
  return request(
    {
      url: '/Server/GetNodeidPower',
      method: 'get',
      params: params
    },
    'http://59.39.62.58:8016'
  )
}

// 获取微信jssdk
export function GetJsSign(params) {
  return request({
    url: '/api/wx/getJsSign',
    method: 'get',
    params: params
  })
}

export function PhoneSign(data) {
  return request(
    {
      url: '/XIGE/PhoneSign',
      method: 'post',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
    },
    cloundserver
  )
}

export function managerPhoneSign(data) {
  return request(
    {
      url: '/XIGE/managerPhoneSign',
      method: 'post',
      data: data,
      headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
    },
    cloundserver
  )
}

export function ini(data) {
  return request(
    {
      url: '/XIGE/ini',
      method: 'post',
      data: data
    },
    cloundserver
  )
}

export function getData(data) {
  return request(
    {
      url: '/XIGE/getData',
      method: 'post',
      data: data
    },
    cloundserver
  )
}
